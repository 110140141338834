import {
  useCreateMailcampaign,
  useFanGroupStore,
  useFetchMailcampaign,
  useUpdateMailcampaign,
} from '@seaters-app/data-access';
import { baseColor400, Button } from '@seaters-app/ui';
import {
  Card,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  theme,
  Typography,
  notification,
} from 'antd';
const { Text } = Typography;
import { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useWLOptions } from '../../../surveys/[id]/hooks/useWLOptions';
import Footer from '../components/Footer';
import { CloseOutlined } from '@ant-design/icons';
import { useCurrentStep } from '../hooks/useCurrentStep';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CreateMailCampaignBody,
  MailcampaignEntity,
} from '@seaters-app/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  MailcampaignFormValues,
  MailcampaignFormValidatorSchema,
} from '@seaters-app/constants';

export function DetailStep() {
  const { next } = useCurrentStep();
  const { t } = useTranslation();
  const { mailcampaignId } = useParams();
  const navigate = useNavigate();

  const { fanGroup } = useFanGroupStore();
  const fanGroupId = fanGroup?.id ?? '';

  const colorPrimary = theme.useToken().token.colorPrimary;

  const [isSubmitting, setIsSubmitting] = useState(false);

  let mailCampaignFormValues: CreateMailCampaignBody | undefined;
  const { data: mailcampaignValues } = useFetchMailcampaign(mailcampaignId);
  mailCampaignFormValues = useMemo(() => {
    if (!mailcampaignValues) return undefined;
    return {
      ...mailcampaignValues,
      wishlist: mailcampaignValues.wishlist?.id,
      fangroup: mailcampaignValues.fanGroup.id,
    };
  }, [mailcampaignValues]);
  const methods = useForm<MailcampaignFormValues>({
    resolver: zodResolver(MailcampaignFormValidatorSchema),
    values: mailCampaignFormValues,
  });
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = methods;
  const recipient = useWatch<MailcampaignFormValues>({
    control,
    name: 'recipient',
  });
  const recipientOptions = [
    {
      value: 'fangroup',
      label: t('mailcampaign_recipient_fangroup'),
      selectWishList: false,
    },
    {
      selectWishList: true,
      value: 'waitinglist',
      label: t('mailcampaign_recipient_waitinglist'),
    },
    {
      selectWishList: true,
      value: 'waitinglist_not_distributed',
      label: t('mailcampaign_recipient_waitinglist_not_distributed'),
    },
    {
      selectWishList: true,
      value: 'waitinglist_distributed_not_accepted',
      label: t('mailcampaign_recipient_waitinglist_distributed_not_accepted'),
    },
    {
      selectWishList: true,
      value: 'waitinglist_distributed_accepted',
      label: t('mailcampaign_recipient_waitinglist_distributed_accepted'),
    },
  ];
  const { waitingListsOptions, loadMoreData: loadMoreWLs } =
    useWLOptions(fanGroupId);

  const handleScrollWLs = (e: any) => {
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      loadMoreWLs();
    }
  };

  const { mutate: createMailCampaign } = useCreateMailcampaign();
  const { mutate: updateMailCampaign } = useUpdateMailcampaign();

  const submit = (
    data: MailcampaignFormValues,
    successCallback: (data: MailcampaignEntity) => void
  ) => {
    setIsSubmitting(true);
    const callbacks = {
      onSuccess: (data: MailcampaignEntity) => {
        successCallback(data);
        setIsSubmitting(false);
      },
      onError: (error: any) => {
        notification.error({
          message: t('notification_error_mailcampaign_updated'),
          description: error.response?.data?.message || error.message,
        });
        setIsSubmitting(false);
      },
    };
    if (mailcampaignId) {
      updateMailCampaign({ id: mailcampaignId, body: data }, callbacks);
    } else {
      createMailCampaign(
        { body: { ...data, fangroup: fanGroupId } },
        callbacks
      );
    }
  };
  const onNextStep = (data: MailcampaignFormValues) => {
    submit(data, (response: MailcampaignEntity) => {
      if (!mailcampaignId) {
        navigate(response.id);
      }
      next();
    });
  };

  return (
    <>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <FormProvider {...methods}>
          <Form
            layout="vertical"
            onFinish={handleSubmit(onNextStep)}
            autoComplete="off"
            disabled={isSubmitting}
          >
            <Row align="middle">
              <Col xs={24} md={24} xl={12}>
                <Form.Item
                  label={<Text strong>{t('mailcampaign_name')}</Text>}
                  required
                  name="name"
                  help={errors.name?.message}
                >
                  <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <>
                        <Input
                          status={!!errors.name ? 'error' : undefined}
                          {...field}
                          size="middle"
                        />
                      </>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col xs={24} md={24} xl={12}>
                <Form.Item
                  label={<Text strong>{t('mailcampaign_subject')}</Text>}
                  required
                  name="subject"
                  help={errors.subject?.message}
                >
                  <Controller
                    control={control}
                    name="subject"
                    render={({ field }) => (
                      <Input
                        status={!!errors.subject ? 'error' : undefined}
                        {...field}
                        size="middle"
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col xs={24} md={24} xl={12}>
                <Form.Item
                  label={<Text strong>{t('mailcampaign_reply_to')}</Text>}
                  required
                  name="replyTo"
                  help={errors.replyTo?.message}
                >
                  <Controller
                    control={control}
                    name="replyTo"
                    render={({ field }) => (
                      <Input
                        status={!!errors.replyTo ? 'error' : undefined}
                        {...field}
                        size="middle"
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col xs={24} md={24} xl={12}>
                <Form.Item
                  label={<Text strong>{t('mailcampaign_recipient')}</Text>}
                  name="recipient"
                >
                  <Controller
                    control={control}
                    name="recipient"
                    render={({ field }) => (
                      <>
                        <Radio.Group
                          style={{ width: '100%', minWidth: 300 }}
                          {...field}
                        >
                          {recipientOptions.map((option) => (
                            <Card
                              bordered
                              key={option.value}
                              style={{
                                marginBottom: 8,
                                borderColor:
                                  recipient === option.value
                                    ? colorPrimary
                                    : baseColor400,
                              }}
                            >
                              <Radio value={option.value}>{option.label}</Radio>
                              {option.selectWishList &&
                                recipient === option.value && (
                                  <Form.Item
                                    required
                                    name="wishlist"
                                    style={{ marginBottom: 0 }}
                                  >
                                    <Controller
                                      control={control}
                                      name="wishlist"
                                      render={({ field }) => (
                                        <Select
                                          style={{
                                            marginTop: 16,
                                          }}
                                          allowClear
                                          showSearch
                                          optionFilterProp="label"
                                          options={waitingListsOptions}
                                          onPopupScroll={handleScrollWLs}
                                          {...field}
                                        />
                                      )}
                                    />
                                  </Form.Item>
                                )}
                            </Card>
                          ))}
                        </Radio.Group>
                      </>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Footer
              cancelButton={
                <Button
                  size="middle"
                  type="default"
                  icon={<CloseOutlined rev={undefined} />}
                  onClick={() => navigate('..')}
                  disabled={isSubmitting}
                >
                  {t('general_cancel')}
                </Button>
              }
              saveAsDraft={() => {
                submit(getValues(), () => navigate('..'));
              }}
              isLoading={isSubmitting}
            ></Footer>
          </Form>
        </FormProvider>
      </Space>
      <Spin
        fullscreen
        spinning={isSubmitting}
        size="large"
        tip={t('mailcampaigns_submitting')}
      />
    </>
  );
}
